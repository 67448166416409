import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  MiddlePipeL,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  IRCompressor,
  PipeFork,
  AftercoolerFan,
  ConnectionIndicator,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  // Totals,
  Alarms,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  ToggleControl,
  MultiInfoButton,
  AlarmReset,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function CompressorScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(4),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(4);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment4 = props.locations?.locations?.[4]?.equipment;
  if (!props.locations?.locations?.[4]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Compressors">
            <InfoControlRow>
              <MobileStatTable
                title="Air Metrics"
                stats={
                [
                  {
                    description: 'Discharge Air Flow',
                    stat: locationEquipment4?.Other?.ioValues?.discharge_air_flow?.value,
                    label: locationEquipment4?.Other?.ioValues?.discharge_air_flow?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Air Pressure',
                    stat: locationEquipment4?.Other?.ioValues?.discharge_air_pressure?.value,
                    label: locationEquipment4?.Other?.ioValues?.discharge_air_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Air Temperature',
                    stat: useCelsius ? locationEquipment4?.Other?.ioValues?.discharge_air_temperature_alternate?.value : locationEquipment4?.Other?.ioValues?.discharge_air_temperature?.value,
                    label: useCelsius ? locationEquipment4?.Other?.ioValues?.discharge_air_temperature_alternate?.ioValueType?.units : locationEquipment4?.Other?.ioValues?.discharge_air_temperature?.ioValueType?.units,
                    precision: 1,
                  },
                  {
                    description: 'System Air Temperature',
                    stat: useCelsius ? locationEquipment4?.Other?.ioValues?.system_air_temperature_alternate?.value : locationEquipment4?.Other?.ioValues?.system_air_temperature?.value,
                    label: useCelsius ? locationEquipment4?.Other?.ioValues?.system_air_temperature_alternate?.ioValueType?.units : locationEquipment4?.Other?.ioValues?.system_air_temperature?.ioValueType?.units,
                    precision: 1,
                  },
                ]
              }
              />
              <VFDPumpControl
                title="AC1 PID"
                settings
                inputNumWidth="64px"
                pumpData={{ id: `controlValve_${locationEquipment4?.['AC1 PID']?.id}` }}
                pidData={locationEquipment4?.['AC1 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: useCelsius ? 'auto_setpoint_alternate' : 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  iGainSetpoint: 'igain_setpoint',
                  pGainSetpoint: 'pgain_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
              />
              <VFDPumpControl
                title="AC2 PID"
                settings
                inputNumWidth="64px"
                pumpData={{ id: `controlValve_${locationEquipment4?.['AC2 PID']?.id}` }}
                pidData={locationEquipment4?.['AC2 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: useCelsius ? 'auto_setpoint_alternate' : 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  iGainSetpoint: 'igain_setpoint',
                  pGainSetpoint: 'pgain_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
              />
              <Info
                title="Instrument Air"
                statistic={locationEquipment4?.Other?.ioValues?.instrument_air_enabled?.value === 1 ? 'Enabled' : 'Low Pressure'}
                color={locationEquipment4?.Other?.ioValues?.instrument_air_enabled?.value === 1 ? theme.onGreen : theme.alarm}
                label=""
              />
              <MultiInfoButton
                title="Air Flow Totals"
                titles={['Trip', 'Total']}
                statistics={[
                  locationEquipment4?.['Air FT']?.ioValues?.trip_volume?.value,
                  locationEquipment4?.['Air FT']?.ioValues?.total_volume?.value,
                ]}
                labels={[
                  locationEquipment4?.['Air FT']?.ioValues?.trip_volume?.ioValueType?.units,
                  locationEquipment4?.['Air FT']?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                buttonWriteIds={[
                  locationEquipment4?.['Air FT']?.ioValues?.trip_volume_reset?.id,
                  locationEquipment4?.['Air FT']?.ioValues?.total_volume_reset?.id,
                ]}
                buttonWriteValues={[1, 1]}
                buttonTexts={['Reset', 'Reset']}
                setIOValue={props.setIOValue}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment4?.Other?.ioValues}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment4?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarmReset: 'alarm_reset',
                  masterAlarm: 'master_alarm',
                }}
                resetWriteValue={1}
              />
              <Info
                title="Room Temperature"
                statistic={useCelsius ? locationEquipment4?.Other?.ioValues?.room_temperature_alternate?.value : locationEquipment4?.Other?.ioValues?.room_temperature?.value}
                label={useCelsius ? locationEquipment4?.Other?.ioValues?.room_temperature_alternate?.ioValueType?.units : locationEquipment4?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[4], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[4], theme)}
              />
            </InfoControlRow>
            <PumpsRowMedium styles="position: relative;">
              <div css={css`margin-left: 20px; display: flex; flex-direction: column; position: absolute; height: 100%; width: 52%;`}>
                <div css={css`height: 50%; margin: 7px 0px 17px 0px;`}>
                  <PipePlaceholder horizontalBelow />
                </div>
                <PipePlaceholder horizontalAbove color={theme.pipeColors.hotWater} />
              </div>
              <IRCompressor
                location={props.locations?.locations?.[4]?.name}
                compressorData={locationEquipment4?.C1}
                pipeAbove="right"
                controlPositionTop="-35px"
                controlPositionLeft="10px"
                pipeTypeBelow={[true, false, true]}
                pipeColorAbove={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                statuses={[
                  {
                    id: locationEquipment4?.C1?.id,
                    label: 'Status',
                    status: locationEquipment4?.C1?.textStatusKey,
                    statusKey: {
                      0: '',
                      1: 'Ready',
                      2: 'Unloaded',
                      3: 'Loaded',
                      4: 'Alarm',
                    },
                    statusColorKey: {
                      0: theme.auto,
                      1: theme.onGreen,
                      2: theme.autoStop,
                      3: theme.auto,
                      4: theme.alarm,
                    },
                  },
                ]}
                IOValueKeys={{
                  compressorStartCommand: 'compressor_start_command',
                  compressorStopCommand: 'compressor_stop_command',
                  compressorModulate: 'compressor_modulate',
                  compressorModulateCommand: 'compressor_modulate_command',
                  compressorUnloaded: 'compressor_unloaded',
                  compressorUnloadCommand: 'compressor_unload_command',
                  compressorResetCommand: 'compressor_reset_command',
                  compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
                  systemPressureSetpoint: 'system_pressure_setpoint',
                  stage1Temperature: 'stage1_temperature',
                  stage1Pressure: 'stage1_pressure',
                  stage1Vibration: 'stage1_vibration',
                  stage2Temperature: 'stage2_temperature',
                  stage2Pressure: 'stage2_pressure',
                  stage2Vibration: 'stage2_vibration',
                  stage3Temperature: 'stage3_temperature',
                  stage3Pressure: 'stage3_pressure',
                  stage3Vibration: 'stage3_vibration',
                }}
                stats={[
                  {
                    stat: locationEquipment4?.C1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment4?.C1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment4?.C1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment4?.C1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment4?.C1?.ioValues?.oil_temperature?.value,
                    locationEquipment4?.C1?.ioValues?.oil_pressure?.value,
                    locationEquipment4?.C1?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment4?.C1?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationEquipment4?.C1?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment4?.C1?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment4?.C1?.ioValues?.oil_temperature?.id,
                    locationEquipment4?.C1?.ioValues?.oil_pressure?.id,
                    locationEquipment4?.C1?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment4?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment4?.C1?.ioValues?.system_pressure?.value,
                    locationEquipment4?.C1?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment4?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment4?.C1?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment4?.C1?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment4?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment4?.C1?.ioValues?.system_pressure?.id,
                    locationEquipment4?.C1?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <IRCompressor
                location={props.locations?.locations?.[4]?.name}
                compressorData={locationEquipment4?.C2}
                pipeAbove="middle"
                controlPositionTop="-35px"
                controlPositionLeft="10px"
                pipeTypeBelow={[false, false, false]}
                pipeColorAbove={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                statuses={[
                  {
                    id: locationEquipment4?.C2?.id,
                    label: 'Status',
                    status: locationEquipment4?.C2?.textStatusKey,
                    statusKey: {
                      0: '',
                      1: 'Ready',
                      2: 'Unloaded',
                      3: 'Loaded',
                      4: 'Alarm',
                    },
                    statusColorKey: {
                      0: theme.auto,
                      1: theme.onGreen,
                      2: theme.autoStop,
                      3: theme.auto,
                      4: theme.alarm,
                    },
                  },
                ]}
                IOValueKeys={{
                  compressorStartCommand: 'compressor_start_command',
                  compressorStopCommand: 'compressor_stop_command',
                  compressorModulate: 'compressor_modulate',
                  compressorModulateCommand: 'compressor_modulate_command',
                  compressorUnloaded: 'compressor_unloaded',
                  compressorUnloadCommand: 'compressor_unload_command',
                  compressorResetCommand: 'compressor_reset_command',
                  compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
                  systemPressureSetpoint: 'system_pressure_setpoint',
                  stage1Temperature: 'stage1_temperature',
                  stage1Pressure: 'stage1_pressure',
                  stage1Vibration: 'stage1_vibration',
                  stage2Temperature: 'stage2_temperature',
                  stage2Pressure: 'stage2_pressure',
                  stage2Vibration: 'stage2_vibration',
                  stage3Temperature: 'stage3_temperature',
                  stage3Pressure: 'stage3_pressure',
                  stage3Vibration: 'stage3_vibration',
                }}
                stats={[
                  {
                    stat: locationEquipment4?.C2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment4?.C2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment4?.C2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment4?.C2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment4?.C2?.ioValues?.oil_temperature?.value,
                    locationEquipment4?.C2?.ioValues?.oil_pressure?.value,
                    locationEquipment4?.C2?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment4?.C2?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationEquipment4?.C2?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment4?.C2?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment4?.C2?.ioValues?.oil_temperature?.id,
                    locationEquipment4?.C2?.ioValues?.oil_pressure?.id,
                    locationEquipment4?.C2?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment4?.C2?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationEquipment4?.C2?.ioValues?.system_pressure?.value,
                    locationEquipment4?.C2?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment4?.C2?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationEquipment4?.C2?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment4?.C2?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment4?.C2?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationEquipment4?.C2?.ioValues?.system_pressure?.id,
                    locationEquipment4?.C2?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <div css={[styles.flexRowBox, css`z-index: 2;`]}>
                <IRCompressor
                  minWidth="0px"
                  location={props.locations?.locations?.[4]?.name}
                  compressorData={locationEquipment4?.C3}
                  pipeAbove="middle"
                  switchHotColdBelow
                  controlPositionTop="-35px"
                  controlPositionLeft="10px"
                  pipeTypeBelow={[false, false, false]}
                  pipeColorAbove={theme.pipeColors.air}
                  setIOValue={props.setIOValue}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  statuses={[
                    {
                      id: locationEquipment4?.C3?.id,
                      label: 'Status',
                      status: locationEquipment4?.C3?.textStatusKey,
                      statusKey: {
                        0: '',
                        1: 'Ready',
                        2: 'Unloaded',
                        3: 'Loaded',
                        4: 'Alarm',
                      },
                      statusColorKey: {
                        0: theme.auto,
                        1: theme.onGreen,
                        2: theme.autoStop,
                        3: theme.auto,
                        4: theme.alarm,
                      },
                    },
                  ]}
                  IOValueKeys={{
                    compressorStartCommand: 'compressor_start_command',
                    compressorStopCommand: 'compressor_stop_command',
                    compressorModulate: 'compressor_modulate',
                    compressorModulateCommand: 'compressor_modulate_command',
                    compressorUnloaded: 'compressor_unloaded',
                    compressorUnloadCommand: 'compressor_unload_command',
                    compressorResetCommand: 'compressor_reset_command',
                    compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
                    systemPressureSetpoint: 'system_pressure_setpoint',
                    stage1Temperature: 'stage1_temperature',
                    stage1Pressure: 'stage1_pressure',
                    stage1Vibration: 'stage1_vibration',
                    stage2Temperature: 'stage2_temperature',
                    stage2Pressure: 'stage2_pressure',
                    stage2Vibration: 'stage2_vibration',
                    stage3Temperature: 'stage3_temperature',
                    stage3Pressure: 'stage3_pressure',
                    stage3Vibration: 'stage3_vibration',
                  }}
                  stats={[
                    {
                      stat: locationEquipment4?.C3?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment4?.C3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment4?.C3?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment4?.C3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                  column1Info={{
                    statistics: [
                      locationEquipment4?.C3?.ioValues?.oil_temperature?.value,
                      locationEquipment4?.C3?.ioValues?.oil_pressure?.value,
                      locationEquipment4?.C3?.ioValues?.inlet_valve?.value,
                    ],
                    subtitles: [
                      'Oil Temp',
                      'Oil Pressure',
                      'Inlet Valve',
                    ],
                    // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                    labels: [
                      locationEquipment4?.C3?.ioValues?.oil_temperature?.ioValueType?.units,
                      locationEquipment4?.C3?.ioValues?.oil_pressure?.ioValueType?.units,
                      locationEquipment4?.C3?.ioValues?.inlet_valve?.ioValueType?.units,
                    ],
                    ids: [
                      locationEquipment4?.C3?.ioValues?.oil_temperature?.id,
                      locationEquipment4?.C3?.ioValues?.oil_pressure?.id,
                      locationEquipment4?.C3?.ioValues?.inlet_valve?.id,
                    ],
                    precisions: [1, 0, 0],
                    mappings: [undefined, undefined, undefined],
                  }}
                  column2Info={{
                    statistics: [
                      locationEquipment4?.C3?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                      locationEquipment4?.C3?.ioValues?.system_pressure?.value,
                      locationEquipment4?.C3?.ioValues?.bypass_valve?.value,
                    ],
                    subtitles: [
                      'Psi Setpoint',
                      'System Pressure',
                      'Bypass Valve',
                    ],
                    // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                    labels: [
                      locationEquipment4?.C3?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                      locationEquipment4?.C3?.ioValues?.system_pressure?.ioValueType?.units,
                      locationEquipment4?.C3?.ioValues?.bypass_valve?.ioValueType?.units,
                    ],
                    ids: [
                      locationEquipment4?.C3?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                      locationEquipment4?.C3?.ioValues?.system_pressure?.id,
                      locationEquipment4?.C3?.ioValues?.bypass_valve?.id,
                    ],
                    precisions: [0, 0, 0],
                    mappings: [undefined, undefined, undefined],
                  }}
                />
                <PipePlaceholder color={theme.pipeColors.air} minWidth="0px" horizontalAbove />
                <div css={[css`height: 100%; display: flex; flex-direction: row; margin-top: -3px;`, styles.hiddenMobile]}>
                  <Statistic
                    label={useCelsius ? locationEquipment4?.Other?.ioValues?.system_air_temperature_alternate?.ioValueType?.units : locationEquipment4?.Other?.ioValues?.system_air_temperature?.ioValueType?.units}
                    border
                    statistic={useCelsius ? locationEquipment4?.Other?.ioValues?.system_air_temperature_alternate?.value : locationEquipment4?.Other?.ioValues?.system_air_temperature?.value}
                    precision={1}
                  />
                </div>
                <PipePlaceholder color={theme.pipeColors.air} minWidth="0px" horizontalAbove />
              </div>
              <div css={styles.flexRowBox}>
                <div css={css`display: flex; flex-direction: column; margin-top: -8px; width: 77px;`}>
                  <AftercoolerFan
                    // pipes
                    // pipeAbove="middle"
                    // pipeColorAbove={theme.pipeColors.air}
                    setIOValue={props.setIOValue}
                    imgStyle="height: 94px;"
                    controlPositionTop="-17px"
                    controlPositionLeft="-7px"
                    fanData={{
                      ...locationEquipment4?.AC1,
                      name: 'AC1',
                      state: locationEquipment4?.AC1?.ioValues?.run_feedback?.value,
                      fanColor: locationEquipment4?.AC1?.ioValues?.run_feedback?.value === 1 ? 'green' : '',
                    }}
                    stateColorKey={{
                      0: `${theme.secondary}`,
                      1: `${theme.onGreen}`,
                      2: `${theme.warning}`,
                      3: `${theme.alarm}`,
                    }}
                    IOValueKeys={{
                      start: 'start',
                      stop: 'stop',
                      started: 'stop_start',
                      stopped: 'stop_start',
                    }}
                    stats={[
                      {
                        stat: locationEquipment4?.AC1?.ioValues?.run_time?.value,
                        label: locationEquipment4?.AC1?.ioValues?.run_time?.ioValueType?.units,
                      },
                      {
                        stat: locationEquipment4?.AC1?.ioValues?.temperature?.value,
                        label: locationEquipment4?.AC1?.ioValues?.temperature?.ioValueType?.units,
                        precision: 1,
                      },
                      {
                        stat: locationEquipment4?.AC1?.ioValues?.speed?.value,
                        label: locationEquipment4?.AC1?.ioValues?.speed?.ioValueType?.units,
                      },
                    ]}
                    writeValues={[1, 1]}
                    readValues={[1, 0]}
                  />
                  <AftercoolerFan
                      // pipes
                      // pipeAbove="middle"
                      // pipeColorAbove={theme.pipeColors.air}
                    setIOValue={props.setIOValue}
                    imgStyle="height: 94px;"
                    controlPositionTop="-10px"
                    controlPositionLeft="-7px"
                    fanData={{
                      ...locationEquipment4?.AC2,
                      name: 'AC2',
                      state: locationEquipment4?.AC2?.ioValues?.run_feedback?.value,
                      fanColor: locationEquipment4?.AC2?.ioValues?.run_feedback?.value === 1 ? 'green' : '',
                    }}
                    stateColorKey={{
                      0: `${theme.secondary}`,
                      1: `${theme.onGreen}`,
                      2: `${theme.warning}`,
                      3: `${theme.alarm}`,
                    }}
                    IOValueKeys={{
                      start: 'start',
                      stop: 'stop',
                      started: 'stop_start',
                      stopped: 'stop_start',
                    }}
                    stats={[
                      {
                        stat: locationEquipment4?.AC2?.ioValues?.run_time?.value,
                        label: locationEquipment4?.AC2?.ioValues?.run_time?.ioValueType?.units,
                      },
                      {
                        stat: locationEquipment4?.AC2?.ioValues?.temperature?.value,
                        label: locationEquipment4?.AC2?.ioValues?.temperature?.ioValueType?.units,
                        precision: 1,
                      },
                      {
                        stat: locationEquipment4?.AC2?.ioValues?.speed?.value,
                        label: locationEquipment4?.AC2?.ioValues?.speed?.ioValueType?.units,
                      },
                    ]}
                    writeValues={[1, 1]}
                    readValues={[1, 0]}
                  />
                </div>
                <PipePlaceholder horizontalAbove color={theme.pipeColors.air} minWidth="0px" />
                <div css={[css`height: 100%; display: flex; flex-direction: row; margin-top: -3px;`, styles.hiddenMobile]}>
                  <Statistic
                    label={locationEquipment4?.Other?.ioValues?.discharge_air_pressure?.ioValueType?.units}
                    border
                    statistic={locationEquipment4?.Other?.ioValues?.discharge_air_pressure?.value}
                  />
                  <Statistic
                    label={locationEquipment4?.Other?.ioValues?.discharge_air_flow?.ioValueType?.units}
                    border
                    statistic={locationEquipment4?.Other?.ioValues?.discharge_air_flow?.value}
                  />
                  <Statistic
                    label={useCelsius ? locationEquipment4?.Other?.ioValues?.discharge_air_temperature_alternate?.ioValueType?.units : locationEquipment4?.Other?.ioValues?.discharge_air_temperature?.ioValueType?.units}
                    border
                    statistic={useCelsius ? locationEquipment4?.Other?.ioValues?.discharge_air_temperature_alternate?.value : locationEquipment4?.Other?.ioValues?.discharge_air_temperature?.value}
                    precision={1}
                  />
                </div>
                <PipePlaceholder horizontalAbove color={theme.pipeColors.air} minWidth="0px" capAfter capLabel="To Hill" capLabelLeft="-46px" capLabelTop="18px" />
              </div>
            </PumpsRowMedium>
            <PumpsRowMedium>
              <div css={[styles.flexRowBox, css`position: relative;`]}>
                <Pump
                  componentMinWidth="0px"
                  controlPositionLeft="-7px"
                  pipeBelow="right"
                  pipeColorBelow={theme.pipeColors.hotWater}
                  setIOValue={props.setIOValue}
                  pumpData={locationEquipment4?.GP1}
                  otherData={locationEquipment4?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  readValues={[1, 0]}
                  writeValues={[1, 1]}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'stop_start',
                    stopped: 'stop_start',
                  }}
                  alarms={[]}
                  stats={[
                    {
                      stat: locationEquipment4?.GP1?.ioValues?.run_time?.value,
                      label: locationEquipment4?.GP1?.ioValues?.run_time?.ioValueType?.units,
                    },
                  ]}
                />
                <PipePlaceholder color={theme.pipeColors.hotWater} minWidth="0px" pipeAbove="left" pipeBelow="left" />
                <div css={css`position: absolute; height: 15px; width: 100%; display: flex; flex-direction: row; padding: 0px 40.5px 0px 40.5px;`}>
                  <PipePlaceholder color={theme.pipeColors.hotWater} pipeBelow="right" minWidth="0px" />
                  <PipePlaceholder hidden minWidth="0px" />
                </div>
              </div>
              <PipePlaceholder hidden />
              <div css={[styles.flexRowBox, css`position: relative`]}>
                <div css={[styles.flexRowBox, css`z-index: 1; position: absolute; margin-top: -52px; height: 130%;`]}>
                  <PipePlaceholder color={theme.pipeColors.hotWater} capAfter capLabel="To Lodge" capLabelTop="40px" capLabelLeft="-30px" />
                </div>
                <div css={[styles.flexRowBox, css`z-index: 1; position: absolute; margin-left: 42px; margin-top: -52px; height: 130%;`]}>
                  <PipePlaceholder capAfter capLabel="From Lodge" capLabelTop="-55px" capLabelLeft="-39px" />
                </div>
                <div css={css`z-index: 2; margin-top: -8px;`}>
                  <Pump
                    noPipes
                    controlPositionLeft="-15px"
                    setIOValue={props.setIOValue}
                    pumpData={locationEquipment4?.HR1}
                    otherData={locationEquipment4?.Other}
                    stateColorKey={{
                      0: `${theme.secondary}`,
                      1: `${theme.onGreen}`,
                      2: `${theme.warning}`,
                      3: `${theme.alarm}`,
                    }}
                    readValues={[1, 0]}
                    writeValues={[1, 1]}
                    IOValueKeys={{
                      start: 'start',
                      stop: 'stop',
                      started: 'stop_start',
                      stopped: 'stop_start',
                    }}
                    alarms={[]}
                    stats={[
                      {
                        stat: locationEquipment4?.HR1?.ioValues?.run_time?.value,
                        label: locationEquipment4?.HR1?.ioValues?.run_time?.ioValueType?.units,
                      },
                    ]}
                  />
                </div>
              </div>
              <PipePlaceholder hidden />
            </PumpsRowMedium>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

CompressorScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

CompressorScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(CompressorScreen);
